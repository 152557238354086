import Vue from 'vue';
import VueRouter from 'vue-router';

import App from './App.vue';
import Main from './pages/Main.vue';
import OldMain from './pages/OldMain.vue';
import ContactUs from './pages/ContactUs.vue';
import PrivacyPolicy from "./pages/PrivacyPolicy";

import Default from "./components/layouts/Default";
import NoIcons from "./components/layouts/NoIcons";
import Light from "./components/layouts/Light";

Vue.component("default-layout", Default);
Vue.component("light-layout", Light);
Vue.component("no-icons-layout", NoIcons);

import * as VeeValidate from 'vee-validate';

const dict = {
    custom: {
        first_name: {
            required: () => 'Enter your first name'
        },
        last_name: {
            required: () => 'Enter your last name'
        },
        email: {
            required: () => 'Your business email'
        },
        company_name: {
            required: () => 'Your company name'
        },
        department: {
            required: () => 'Enter your department'
        },
        company_website: {
            required: () => 'Enter your department'
        },
        message: {
            required: () => 'Please tell us what brought you to us. A bit of context will help us to better assist you.'
        },
        aboutYouSelected: {
            required: () => 'Please select'
        },
        aboutUsSelected: {
            required: () => 'Please select'
        },
    }
};

Vue.use(VeeValidate);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    localized: true,
    routes: [
        {path: '/', component: Main, name: 'Main', meta: {layout: "default"}},
        {path: '/contact', name: 'ContactUs', component: ContactUs, meta: {layout: "no-icons"}},
        {path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy, meta: {layout: "light"}},
        {path: '*', name: 'OldMain', component: OldMain, meta: {layout: "default"}}
    ]
});

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App),
    mounted() {
        this.$validator.localize('en', dict)
    }
},).$mount('#app');
