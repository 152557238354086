<template>
    <div class="main-container">

        <contact-us v-if="contactUsVisible" @close="contactUsVisible = false"></contact-us>

        <nav>
            <template v-for="(n, index) in navBtns">
                <div :key="index"
                     class="bullet"
                     :class="activeNavBtn === index ? 'active' : ''"
                     @click="activateNavBtn(index)"></div>
            </template>
        </nav>

        <main-content :active-slide="activeNavBtn"></main-content>

    </div>
</template>

<script>
    import ContactUs from "../components/ContactUs";
    import MainContent from "../components/MainContent";

    export default {
        name: "Main",
        components: {MainContent, ContactUs},
        data() {
            return {
                contactUsVisible: false,
                navBtns: 6,
                activeNavBtn: 0,
                carouselInterval: null
            }
        },
        mounted() {
            this.setCarouselInterval();
        },
        methods: {
            activateNavBtn(index) {
                this.activeNavBtn = index;
                clearInterval(this.carouselInterval);
                this.setCarouselInterval();
            },
            setCarouselInterval() {
                this.carouselInterval = setInterval(() => {
                    let self = this;
                    self.activeNavBtn = (self.activeNavBtn + 1) % self.navBtns;
                    this.activateNavBtn(self.activeNavBtn);
                }, 8000)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../design/index";

    .main-container {
        width: 100%;
    }

    nav {
        position: absolute;
        right: 66px;
        top: 50%;
        transform: translateY(-50%);

        .bullet {
            width: calc(6px + 0.3vw);
            height: calc(6px + 0.3vw);
            border: 1px solid $white;
            border-radius: 50%;
            margin: calc(15px + 4.2vh) 0;
            position: relative;

            &:before {
                content: "";
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                border: 1px solid $white;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
            }

            &:hover {
                cursor: pointer;
            }

            &.active {
                border-color: $blue;
                background-color: $blue;

                &:before {
                    border: 1px solid #4b7aff;
                    width: 400%;
                    height: 400%;
                }
            }
        }
    }


    @media only screen and (max-width: 900px) {

        .main-container {
            width: 100%;
            padding: calc(20px + 20vh) 0;
        }

        .main-content-container {
            width: 100%;
            left: 0;
            padding-top: 7%;
            margin-top: 0;
        }

        nav {
            position: relative;
            top: auto;
            right: auto;
            transform: translateY(0);

            .bullet{
                margin: 0 calc(15px + 4.4vw) 0 0;
                display: inline-block;
                position: relative;
                min-width: 7px;
            }
        }

        .divider-line {
            margin: 25px 0;
        }
    }

    @media only screen and (max-width: 420px) {

        .main-container {
            padding: calc(20px + 16vh) 0;
        }

        .main-content-container {
            padding-top: 9%;
        }

    }

    @media only screen and (max-width: 420px) {

        .main-container {
            padding: calc(20px + 14vh) 0;
        }

    }

    @media only screen and (max-height: 600px) {

        .main-container {
            padding: calc(20px + 14vh) 0;
        }

    }

</style>
