<template>

    <div class="contact-us-modal">

    </div>
</template>

<script>
    export default {
        name: "ContactUs"
    }
</script>
