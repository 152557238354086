<template>
    <header class="container">
        <div class="logo-container"><img src="../../assets/icons/marketsonics.svg"></div>

        <nav>
            <ul class="navigation-container">
                <li class="navigation active fs-30">Home</li>
                <li class="navigation fs-30">About</li>
                <li class="navigation fs-30" @click="$emit('makeVisible')">Contact</li>
            </ul>
        </nav>
    </header>
</template>

<script>
    export default {
        name: "HeaderNav"
    }
</script>

<style lang="scss">
    @import '../../design/index';

    .container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 5.5vh 5vw 6vh 3.4vw;

        margin: 0 0 $size-grid-padding;
        text-align: center;
        list-style-type: none;
        box-sizing: border-box;
        z-index: 1;

        .logo-container {
            img {
                max-height: 37px;
            }
        }

        .navigation-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .navigation {
                display: inline-block;
                margin-right: $size-grid-padding;
                color: $white;
                -webkit-transition: all 0.2s;
                -moz-transition: all 0.2s;
                -ms-transition: all 0.2s;
                -o-transition: all 0.2s;
                transition: all 0.2s;
                cursor: pointer;

                &:hover {
                    color: $blue;
                }
            }
        }
    }


    @media only screen and (max-width: 1450px) {

        .container .logo-container img {
            max-height: 30px;
        }

    }


    @media only screen and (max-width: 900px) {

        header.container nav{
            display: none;
        }

    }


</style>
