<template>
    <header>
        <img class="logo" @click="$router.push({name:'Main'})" :src="require(`@/assets/icons/${logo}`)"
             alt="marketsonics-logo">
    </header>
</template>

<script>
    export default {
        name: "MainHeader",
        props: {
            logo: {
                type: String,
                default: "ms-logo.svg"
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../design/index";

</style>
