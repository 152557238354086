<template>
    <div class="layout light">
        <main-header class="fixed" :logo="'ms-logo-dark.svg'"></main-header>
        <slot/>
        <main-footer class="light bottom"></main-footer>
    </div>
</template>

<script>
    import MainHeader from "../MainHeader";
    import MainFooter from "../MainFooter";
    export default {
        name: "Light",
        components: {MainHeader, MainFooter}
    }
</script>

<style scoped lang="scss">
    @import "../../design/index";
</style>
