<template>

  <div class="contact-us-modal" :class="{'z-1000': popupVisible || bgVisible}">

    <div class="contact-us">
      <h1 class="fs-80">Contact Us</h1>
      <p class="fs-26">We’d love to talk about how we can help you.</p>

      <form class="contact-inputs" id="contactUs" @submit.prevent="sendEmail">
        <div class="input-container" :class="{'error' : errors.first('first_name')}">
          <span class="fs-26 avenir-book">First name <span class="asterisk">*</span></span>
          <input type="text" v-validate="'required'" name="first_name"
                 v-model="first_name" class="fs-26" placeholder="Enter your first name">
        </div>

        <div class="input-container" :class="{'error' : errors.first('last_name')}">
          <span class="fs-26">Last name <span class="asterisk">*</span></span>
          <input type="text" v-validate="'required'" name="last_name" v-model="last_name" class="fs-26"
                 placeholder="Enter your last name">
        </div>

        <div class="input-container" :class="{'error' : errors.first('email')}">
          <span class="fs-26">Email <span class="asterisk">*</span></span>
          <input type="email" v-validate="'required|email'" name="email" v-model="email" class="fs-26"
                 placeholder="Enter your email">
          <span v-if="isFormatError" class="error-message">The email field must be a valid email</span>
        </div>

        <div class="input-container" :class="{'error' : errors.first('company_name')}">
          <span class="fs-26">Company name <span class="asterisk">*</span></span>
          <input type="text" v-validate="'required'" name="company_name" v-model="company_name" class="fs-26"
                 placeholder="Enter your company name">
        </div>

        <div class="input-container" :class="{'error' : errors.first('department')}">
          <span class="fs-26">Department <span class="asterisk">*</span></span>
          <input type="text" v-validate="'required'" name="department" v-model="department" class="fs-26"
                 placeholder="Enter your department">
        </div>

        <div class="input-container" :class="{'error' : errors.first('company_website')}">
          <span class="fs-26">Company website <span class="asterisk">*</span></span>
          <input type="text" v-validate="'required'" name="company_website" v-model="company_website"
                 class="fs-26" placeholder="Enter your company’s website">
        </div>

        <div class="input-container" :class="{'error' : errors.first('aboutYouSelected')}">
          <span class="fs-26">Requesting on behalf of <span class="asterisk">*</span></span>
          <div class="dropdown-container">
            <div class="dropdown-header-container">
              <input type="text" v-validate="'required'" name="aboutYouSelected" class="fs-26"
                     @focus="aboutYouFocused = true" onkeypress="return false;" autocomplete="off"
                     @blur="aboutYouFocused = false" v-model="aboutYouSelected.value"
                     placeholder="Please select">
              <div class="arrow" :class="{'rotate': aboutYouFocused}"><img src="../assets/icons/arrow.svg"
                                                                           alt=""></div>
            </div>

            <div class="dropdown-body-container" :class="{'visible': aboutYouFocused}">
              <p v-for="option in aboutYouOptions"
                 @click="aboutYouSelected = option; aboutYouFocused = false" :id="option.id"
                 v-bind:key="option.id" class="fs-26 pointer">
                {{ option.value }}</p>
            </div>
          </div>
        </div>

        <div class="input-container" :class="{'error' : errors.first('aboutUsSelected')}">
          <span class="fs-26">How did you hear about us? <span class="asterisk">*</span></span>
          <!--<input type="text" class="fs-26" placeholder="Please select">-->
          <div class="dropdown-container">
            <div class="dropdown-header-container">
              <input type="text" v-validate="'required'" name="aboutUsSelected" class="fs-26"
                     @focus="aboutUsFocused = true" onkeypress="return false;" autocomplete="off"
                     @blur="aboutUsFocused = false" v-model="aboutUsSelected.value"
                     placeholder="Please select">
              <div class="arrow" :class="{'rotate': aboutUsFocused}"><img src="../assets/icons/arrow.svg"
                                                                          alt=""></div>
            </div>

            <div class="dropdown-body-container" :class="{'visible': aboutUsFocused}">
              <p v-for="option in aboutUsOptions"
                 @click="aboutUsSelected = option; aboutUsFocused = false" :id="option.id"
                 v-bind:key="option.id" class="fs-26">
                {{ option.value }}</p>
            </div>
          </div>

        </div>

        <div class="input-container textarea w-100" :class="{'error' : errors.first('message')}">
          <span class="fs-26">Message <span class="asterisk">*</span></span>
          <textarea class="fs-26" v-validate="'required'" v-model="message" name="message"
                    placeholder="Please tell us what brought you to us. A bit of context will help us to better assist you."></textarea>
        </div>

        <div id="g-recaptcha-container" data-sitekey="user_jzDNF6KHpaWjMMeAF5adn"
             data-expired-callback="recaptchaExpired"></div>

        <p class="privacy-policy-container avenir-black pointer" @click="redirectToPrivacyPolicy">Privacy
          policy</p>

        <button type="submit" class="fs-26" :disabled="!isFormValid">
          Submit
        </button>
      </form>
    </div>

    <div class="popup-background" v-if="bgVisible">
      <h2 class="fs-80" v-if="bgVisible && !popupVisible">Sending...</h2>
      <div class="success-error-container" v-if="popupVisible">
        <img src="../assets/icons/success.svg">
        <div class="middle">
          <h1 class="fs-80">Message Sent!</h1>
          <p class="fs-26">Thank you for contacting MarketSonics! </p>
          <p class="fs-26"> We will be in touch with you shortly.</p>
        </div>

        <button class="fs-26" @click="closePopup()">Close</button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContactUs",

  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      department: '',
      company_website: '',
      message: '',
      time_stamp: '',
      user_agent: '',
      focused: false,
      aboutYouFocused: false,
      aboutUsFocused: false,
      aboutYouSelected: {},
      aboutUsSelected: {},
      aboutYouOptions: [
        {
          id: 1,
          value: 'Prospective Customer'
        },
        {
          id: 2,
          value: 'Prospective Partner'
        },
        {
          id: 3,
          value: 'Current Customer'
        },
        {
          id: 4,
          value: 'Other'
        },
      ],
      aboutUsOptions: [
        {
          id: 1,
          value: 'Web Search'
        },
        {
          id: 2,
          value: 'Advertisement'
        },
        {
          id: 3,
          value: 'Social Media'
        },
        {
          id: 4,
          value: 'News Article'
        },
        {
          id: 5,
          value: 'Friend/Colleague'
        },
        {
          id: 6,
          value: 'Current Customer'
        },
        {
          id: 7,
          value: 'Current Partner'
        },
        {
          id: 8,
          value: 'Other'
        },
      ],

      recaptcha: null,
      server: null,
      popupVisible: false,
      bgVisible: false,
      emailErrorMessage: ''
    }
  },

  mounted: function () {
    this.grecaptcha_callback();
  },

  computed: {
    isFormValid: function () {
      return !this.errors.any()
          && this.first_name !== ''
          && this.last_name !== ''
          && this.email !== ''
          && this.company_name !== ''
          && this.department !== ''
          && this.company_website !== ''
          && this.aboutYouSelected.value !== ''
          && this.aboutUsFocused.value !== ''
          && this.recaptcha !== null
    },
    isFormatError: function () {
      return (this.errors.first('email') === 'The email field must be a valid email')
    },
  },

  methods: {
    sendEmail() {
      this.bgVisible = true;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
      axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
      this.time_stamp = Date.now();
      this.user_agent = navigator.userAgent;
      axios.post('https://rnydimluo8.execute-api.us-east-1.amazonaws.com/saveContactUsInfo', {
        "g-recaptcha-response": this.recaptcha,
        "first_name": this.first_name,
        "last_name": this.last_name,
        "email": this.email,
        "company_name": this.company_name,
        "department": this.department,
        "company_website": this.company_website,
        "user_agent": this.user_agent,
        "epoch": this.time_stamp,
        "message": this.message,
        "about_you": this.aboutYouSelected.value,
        "how_did_you_hear_about_us": this.aboutUsSelected.value,
        "screen_resolution": {
          width: window.screen.width * window.devicePixelRatio,
          height: window.screen.height * window.devicePixelRatio
        },
        "languages": window.navigator.languages,
      }).then(() => {
        // console.log("lambda response", JSON.stringify(response));
        this.popupVisible = true;
        this.bgVisible = true;
        setTimeout(() => {
          this.popupVisible = false;
          this.bgVisible = false;
          let routeData = this.$router.resolve({path: '/'});
          window.open(routeData.href, "_self");
        }, 2000);
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.company_name = '';
        this.department = '';
        this.company_website = '';
        this.message = '';
        this.aboutYouSelected = {};
        this.aboutUsSelected = {};
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      }, (error) => {
        alert('FAILED');
        this.popupVisible = false;
        this.bgVisible = false;
        console.log('FAILED...', error);
      });
    },

    grecaptcha_callback() {
      let self = this;

      window._grecaptcha_callback = () => {
        let container = document.getElementById('g-recaptcha-container');
        if (container) {
          var verifyCallback = function (response) {
            self.recaptcha = response;
          };
          var expiredCallback = function () {
            self.recaptcha = null;
          };

          container.innerHTML = '';
          let recaptcha = document.createElement('div');
          window.grecaptcha.render(recaptcha, {
            'sitekey': '6LdTIKEbAAAAALiWots6X8cArCcCxwCDwBJhgk_s',
            'callback': verifyCallback,
            'expired-callback': expiredCallback
          });
          window.grecaptcha.ready(() => {
            console.log("recaptcha ready")
            // grecaptcha is ready
          })
          container.appendChild(recaptcha)
        }
      }
      window._grecaptcha_callback();
    },

    redirectToPrivacyPolicy() {
      let routeData = this.$router.resolve({path: 'privacy-policy'});
      window.open(routeData.href, '_blank');
    },

    closePopup() {
      this.popupVisible = false;
      this.bgVisible = false;
    }
  },

}


</script>

<style lang="scss">
@import '../design/index';

textarea {
  resize: none;
}

.contact-us-modal {
  /*position: fixed;*/
  width: 100%;
  height: auto;
  z-index: 1;
  background-color: white;

  &.z-1000 {
    z-index: 1000;
  }

  .close-btn-container {
    background-color: $black;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 3.5vw;
    top: 6vh;

    img {
      max-width: 50%;
    }
  }


  .contact-us {
    width: 41.5%;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding: 4.5vh 0;


    h1 {
      color: $black;
      line-height: 1.75;
    }

    p {
      color: $black;
      width: 100%;
      padding-bottom: 30px;
    }

    .contact-inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .input-container {
        position: relative;
        width: 48%;
        border-bottom: solid 0.5px #131415;
        text-align: left;
        height: 13vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        &.error {
          border-bottom: solid 0.5px $red;

          input::placeholder {
            color: $red;
          }

          textarea::placeholder {
            color: $red;
          }

          textarea {
            border: solid 0.5px $red !important;
          }
        }

        .asterisk {
          color: $red;
        }

        &.textarea {
          border-bottom: none;
          height: auto;
          padding-top: 4.5vh;

          textarea {
            font-family: AvenirLTStd-Book, serif;
            border: solid 0.5px #131415;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            width: 100%;
            height: 15vh;
            margin-top: 2vh;
            padding: 20px 25px;
            box-sizing: border-box;
          }
        }

        .error-message {
          position: absolute;
          bottom: -20px;
          font-size: 12px;
          color: #ee1d1d;
        }

        span {
          font-family: AvenirLTStd-Black, serif;
          margin-right: 15px;
          white-space: nowrap;
        }

        &.w-100 {
          width: 100%;
        }

        input {
          border: none;
          font-family: AvenirLTStd-Book, serif;
          width: 100%;
          outline: none;
          padding: 1.5vh 0;
        }

      }

    }

    .privacy-policy-container {
      color: $blue;
      line-height: 3.5;
    }

    button {
      font-family: AvenirLTStd-Book, serif;
      margin: 0 auto;
      background-color: #4b7aff;
      border: none;
      border-radius: 12px;
      color: white;
      padding: 10px 3.2vw;

      &:disabled {
        opacity: 0.5;
      }
    }


  }

}

.popup-background {
  position: fixed;
  background-color: rgba(19, 20, 21, 0.67);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;

  h2 {
    color: $white;
    font-family: Avenir-Heavy, serif;
    align-self: center;
  }

  .success-error-container {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    width: 48%;
    height: 38vh;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 53px 0;
    justify-content: space-between;

    .middle {
      text-align: center;
    }

    h1 {
      font-family: Avenir-Heavy, serif;
      padding-bottom: 20px;
      /*letter-spacing: 3px;*/
    }

    button {
      background-color: $blue;
      color: $white;
      font-family: Avenir-Medium, serif;
      border: none;
      padding: 1vh 3.6vw;
      border-radius: 12px;
    }

  }
}

.dropdown-container {
  position: relative;
  width: 100%;

  .dropdown-header-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .arrow {
      transition: all 0.2s ease;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-body-container {
    position: absolute;
    width: 100%;
    border: solid 0.5px #131415;
    background-color: white;
    padding: 7px 20px;
    border-radius: 0 0 12px 12px;
    box-sizing: border-box;
    visibility: hidden;
    z-index: 10;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &.visible {
      visibility: visible;
      /*height: 180px;*/
    }

    p {
      padding: 5px 0;

      &:hover {
        color: $blue;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

div#g-recaptcha-container {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

div#g-recaptcha-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1700px) {
  .contact-us-modal .contact-us {
    min-width: 60.5%;
  }
}

@media only screen and (max-width: 1600px) {

  .fs-26 {
    font-size: 24px;
  }

  .fs-80 {
    font-size: 70px;
  }

  .contact-us-modal .contact-us h1 {
    line-height: 1.55;
  }

}

@media only screen and (max-width: 1450px) {

  .fs-26 {
    font-size: 22px;
  }

  .fs-80 {
    font-size: 60px;
  }

  .contact-us-modal .contact-us h1 {
    line-height: 1.55;
  }

}

@media only screen and (max-width: 1024px) {
  .contact-us-modal .contact-us {
    min-width: 80.5%;
  }
}

@media only screen and (max-width: 900px) {
  .contact-us-modal .contact-us .contact-inputs .input-container {
    width: 100%;
    height: auto;
    padding-top: 4vh;
  }
}

@media only screen and (max-width: 600px) {
  .contact-us-modal .contact-us .contact-inputs .input-container.textarea textarea {
    height: 20vh;
  }

  .contact-us-modal .contact-us button {
    padding: 10px 6.2vw;
  }
}

@media only screen and (max-width: 420px) {

  .fs-26 {
    font-size: 18px;
  }

  .fs-80 {
    font-size: 50px;
  }

  .contact-us-modal .contact-us {
    width: 100%;
  }

  .contact-us-modal .contact-us p {
    padding-bottom: 10px;
  }

  .contact-us-modal .contact-us .contact-inputs .input-container.textarea textarea {
    height: 25vh;
  }

  #g-recaptcha-container {
    transform: scale(0.905);
    -webkit-transform: scale(0.905);
    transform-origin: 50% 0;
    -webkit-transform-origin: 50% 0;
    max-width: 100%;
    width: auto;
  }

  .contact-us-modal .contact-us .contact-inputs {
    justify-content: center;
  }
}

@media only screen and (max-width: 375px) {

  .contact-us-modal .contact-us .contact-inputs .input-container {
    padding-top: 2.5vh;
  }

  .contact-us {
    min-width: 85%;
  }
}

@media only screen and (max-width: 320px) {

  .fs-26 {
    font-size: 16px;
  }

  .fs-80 {
    font-size: 40px;
  }

  .contact-us-modal .contact-us .contact-inputs .input-container {
    padding-top: 2vh;
  }
}


</style>
