<template>
    <div class="main-content-container">
        <p class="welcome-message avenir-medium fs-26">welcome to marketsonics</p>
        <div class="divider-line"></div>
        <transition :key="index" name="fade" v-for="(item, index) in texts">
            <p class="main-content-plain-text avenir-book fs-50" v-if="index === activeSlide">
                {{item}} <span class="carousel-message" v-if="lastSlide">{{carouselMessage}}</span>
            </p>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "MainContent",
        data() {
            return {
                texts: ["It’s time to reimagine what a quantitative financial technology company can be.",
                    "What if you could remove the barriers among the components of a quant-fintech development life cycle?",
                    "Teaming up with a partner you can trust for your quant-fintech needs.",
                    "Helping you accelerates product development and innovation from infrastructure to solutions.",
                    "Empowering you to solve challenging problems quickly and efficiently, making you an industry’s elite.",
                    "MarketSonics helps you"],
                carouselMessages: ["deploy infrastructure", "source data", "build analytics", "create solutions"],
                carouselMessageIndex: 0,
                carouselInterval: null
            }
        },
        props: {
            activeSlide: {
                type: Number,
                default: 0
            },
        },
        computed: {
            slideCount() {
                return this.texts.length
            },
            lastSlide() {
                return this.activeSlide === this.slideCount - 1
            },
            carouselMessage() {
                return this.carouselMessages[this.carouselMessageIndex]
            }
        },
        watch: {
            activeSlide() {
                if (this.lastSlide) {
                    let self = this;
                    this.carouselInterval = setInterval(() => {
                        self.carouselMessageIndex = (self.carouselMessageIndex + 1) % self.carouselMessages.length
                    }, 2000)
                } else {
                    clearInterval(this.carouselInterval);
                    this.carouselMessageIndex = 0;
                    // console.log("clear carousel", self)
                }
            }
        },
        mounted() {
            console.log(this.activeSlide);
        }
    }
</script>

<style scoped lang="scss">
    @import "../design/index";

    .main-content-container {
        color: #e4e1dd;
        width: 70%;
        left: 18%;
        position: relative;
        /*padding-top: 18%;*/
        padding-top: 0;
        margin-top: -28px;
    }

    .main-content-plain-text {
        /*position: relative;*/
        position: absolute;
    }

    .welcome-message {
        text-transform: uppercase;
    }

    .carousel-message {
        color: $blue;
    }

    .divider-line {
        height: 1px;
        background-color: $blue;
        width: 100%;
        margin: calc(10px + 1.9vh) 0;
    }

    .fade-enter-active {
        transition: opacity 0.5s;
        transition-delay: 0.5s;
    }

    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    @media only screen and (max-width: 1600px) {
        .main-content-container {
            /*padding-top: 17%;*/
        }

        nav .bullet[data-v-770affdd] {
            margin: calc(15px + 3.8vh) 0;
        }
    }
</style>
