<template>
    <footer class="main-footer">
        <p class="copyright-text avenir-light fs-16">© {{new Date().getFullYear()}} MarketSonics LLC. All rights reserved</p>

        <div class="icon-container">
            <img class="social-icon social-icon-in" src="../assets/icons/linkedin-grey.svg" alt="linkedin-icon">
            <img class="social-icon social-icon-twitter" src="../assets/icons/twitter-grey.svg" alt="twitter-icon">
            <img class="social-icon social-icon-contact" src="../assets/icons/contact.svg"
                 @click="$router.push({name:'ContactUs'})" alt="contact-icon">
        </div>
    </footer>
</template>

<script>
    export default {
        name: "MainFooter",
    }
</script>

<style scoped lang="scss">
    @import "../design/index";

</style>
