<template>
    <div class="contact-info-container">
        <div class="contact-info left">
            <p class="fs-46 avenir-oblique blue"> MarketSonics LLC </p>
            <p class="fs-46 avenir-oblique"> P.O. Box 2122 </p>
            <p class="fs-46 avenir-oblique"> Radio City Station, </p>
            <p class="fs-46 avenir-oblique"> New York, NY 10101</p>
        </div>
        <div class="contact-info right">
            <p class="fs-46 avenirt-oblique">Email:</p>
            <p class="email fs-26 avenir-oblique">legal@marketsonics.com</p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ContactInfo"
    }
</script>

<style lang="scss" scoped>
    @import "../design/index";

    .contact-info-container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-bottom: 80px;

        .contact-info {
            border-left: solid 1px #4b7aff;
            padding: 10px 65px;
            box-sizing: border-box;


            p {
                line-height: 1.32;
            }

            &.left {
                width: 51%;
            }

            &.right {
                width: 45%;

                p {
                    margin-bottom: 30px;
                }

                .email {
                    display: inline-block;
                    background-color: $blue;
                    color: $white;
                    padding: 11px 30px;
                    border-radius: 12px;
                }
            }

            .blue {
                color: $blue;
            }
        }

    }


    @media only screen and (max-width: 720px) {

        .contact-info-container {
            flex-direction: column;

            .contact-info {
                padding: 10px 40px;

                &.left {
                    width: 100%;
                }

                &.right {
                    width: 100%;
                }
            }
        }
    }
</style>
