<template>
    <div id="app" class="">
        <component :is="layout">
            <router-view :key="$route.fullPath"/>
        </component>
    </div>
</template>

<script>

    const default_layout = "default";

    export default {
        name: 'App',
        computed: {
            layout() {
                return (this.$route.meta.layout || default_layout) + "-layout"
            }
        }
    }
</script>

<style lang="scss">
    @import "./design/index";

    html, body {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    #app {
        position: relative;
        width: 100%;
        height: 100%;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        /*color: #2c3e50;*/
        /*margin-top: 60px;*/
    }
</style>
