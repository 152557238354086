<template>

    <div class="privacy-policy-modal">
        <div class="close-btn-container" @click="$emit('close')">
            <img src="../assets/icons/x.svg">
        </div>

        <div class="policy-wrapper">
            <h1 class="fs-130">Terms And Conditions</h1>

            <div class="text-wrapper">


                <p class="fs-30">
                    Apple is committed to your privacy. Read our customer Privacy Policy for a clear explanation of how
                    we
                    collect, use, disclose, transfer, and store your information.
                </p>

                <br>

                <p class="fs-30">
                    To access and manage your Apple data please visit Manage your Apple account. For corrections or
                    general
                    enquiries, please Contact Privacy.
                </p>


                <br>


                <p class="fs-30">
                    In addition to this Privacy Policy, we provide data and privacy information embedded in our products
                    and
                    certain features that ask to use your personal information. This
                    product-specific information is accompanied by our Data & Privacy Icon.
                    You will be given an opportunity to review this product-specific information before
                    enabling these features. You also can view this information at any time, either in
                    Settings related to those features and/or online at apple.com/legal/privacy.
                    Please take a moment to familiarize yourself with our privacy practices, accessible via
                    the headings below, and contact us if you have any questions.
                </p>

                <br>


                <h4 class="fs-30">
                    Your California Privacy Disclosures
                </h4>

                <p class="fs-30">
                    Information Regarding Commercial Electronic Messages in Canada
                    Apple Health Study Apps Privacy Policy
                </p>

                <br>

                <h4 class="fs-30">
                    What Is Personal Data at Apple?
                </h4>

                <p class="fs-30">

                    At Apple, we believe strongly in fundamental privacy rights — and that those fundamental rights
                    should
                    not
                    differ depending on where you live in the world. That’s why we treat any data that relates to an
                    identified or identifiable individual or that is linked or linkable to them by Apple as “personal
                    data,”
                    no
                    matter where the individual lives. This means that data that directly identifies you — such as your
                    name
                    — is personal data, and also data that does not directly identify you, but that can reasonably be
                    used
                    to
                    identify you — such as the serial number of your device — is personal data.
                    This Privacy Policy covers how Apple or an Apple-affiliated company handles personal data whether
                    you
                    interact with us on our websites, through Apple apps (such as Apple Music or Wallet), or in person
                    (including by phone or when visiting our retail stores). Apple may also link to third parties on our
                    services or
                    make third-party apps available for download in our App Store. Apple’s Privacy Policy does not apply
                    to
                    how third parties define personal data or how they use it. We encourage you to read their privacy
                    policies
                    and know your privacy rights before interacting with them.
                    Your Privacy Rights at Apple
                    At Apple, we respect your ability to know, access, correct, transfer, restrict the processing of,
                    and
                    delete
                    your personal data. We have provided these rights to our global customer base and if you choose to
                    exercise these privacy rights, you have the right not to be treated in a discriminatory way nor to
                    receive a
                    lesser degree of service from Apple. Apple does not sell your data including as “sale” is defined in
                    Nevada
                    and California.
                    To exercise your privacy rights and choices, visit the Apple Privacy Portal at privacy.apple.com for
                    Apple or shazam.com/privacy for Shazam. To help protect the security of your personal data, you must
                    sign in to your account and your identity will be verified. If the Privacy Portal is not available
                    in
                    your region,
                    you can make privacy rights requests at apple.com/legal/privacy/contact.
                    There may be situations where we cannot grant your request — for example, if you ask us to delete
                    your
                    transaction data and Apple is legally obligated to keep a record of that transaction to comply with
                    law.
                </p>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TermsAndConditions"
    }
</script>

<style lang="scss">
    @import '../design/index';

    .privacy-policy-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: white;
        overflow-y: scroll;

        .close-btn-container {
            background-color: $black;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: fixed;
            right: 3.5vw;
            top: 6vh;

            img {
                max-width: 50%;
            }
        }

        .policy-wrapper {
            max-width: 100%;
            text-align: left;
            padding: 6.5vh 0;
            margin: 0 10vw;

            .text-wrapper {
                max-width: 82%;
            }
            h1 {
                color: $blue;
                padding-bottom: 6vh;
                font-family: AvenirLTStd-Black, serif;
            }

            h4 {
                font-family: AvenirLTStd-Black, serif;
            }

            p {
                font-family: Avenir-Light, serif;
                color: $black;
            }
        }

    }


    @media only screen and (max-width: 1600px) {
        .privacy-policy-modal .close-btn-container {
            width: 100px;
            height: 100px;
            top: 5vh;
        }
    }

    @media only screen and (max-width: 1450px) {
        .privacy-policy-modal .close-btn-container {
            width: 80px;
            height: 80px;
            top: 4.5vh;
        }
    }


</style>
