<template>
    <div class="layout">
        <!--<video id="main-video" autoplay="autoplay" loop="loop"  :src="require(`@/assets/videos/${video}`)" class="bg-video" ></video>-->

        <div class="vimeo-wrapper">
            <iframe id="main-video" class="bg-video" src="https://player.vimeo.com/video/573390727?background=1&autoplay=1&loop=1&byline=0&title=0"
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
        <main-header></main-header>
        <slot/>
        <main-footer class="dark"></main-footer>
    </div>
</template>

<script>
    import MainHeader from "../MainHeader";
    import MainFooter from "../MainFooter";
    export default {
        name: "Default",
        components: {MainFooter, MainHeader},
        props: {
            video: {
                type: String,
                default: "marketsonics-bg.mp4"
            }
        }
    }
</script>

<style scoped>

    .vimeo-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*z-index: -1;*/
        pointer-events: none;
        overflow: hidden;
    }
    .vimeo-wrapper:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.6);
    }
    .vimeo-wrapper iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /*.bg-video {*/
        /*position: fixed;*/
        /*top: 50%;*/
        /*left: 50%;*/
        /*min-width: 100%;*/
        /*min-height: 100%;*/
        /*transform: translate(-50%, -50%)*/
    /*}*/

</style>
