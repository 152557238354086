<template>
  <div class="privacy-policy avenir-book fs-26">
    <div class="pp-header">
      <p class="header-label avenir-medium fs-26">MARKETSONICS, LLC</p>
      <h1 class="avenir-heavy fs-80">MarketSonics Privacy Policy</h1>
    </div>
    <div class="divider"></div>
    <p class="date avenir-book fs-26">Effective Date: June 09, 2021</p>

    <p>MarketSonics, LLC respects your privacy. This Privacy Policy describes the types of personal data we collect
      from customers using MarketSonics, how we use the personal data, and with whom we share it. We also describe
      the rights you may have and how you can contact us about our privacy practices.</p>
    <p>MarketSonics is responsible for the use of your personal data for the purposes described in this Privacy
      Policy - our contact information can be found in the “How to Contact Us” section.</p>
    <p>If you are a California consumer, for more information about your privacy rights, please see the section of
      this Privacy Policy called “California Consumer Privacy Statement.”</p>
    <p>For further information on how we process your personal data, please use the links below to jump to the
      listed section:</p>

    <ol class="table-of-contents">
      <template v-for="(content, index) in tableOfContents">
        <li :key="index">
          <a class="avenir-medium" :href="'#' + getContentId(content)" @click="activateSection(index)">{{
              index
              + 1
            }}.
            {{ content }}</a>
        </li>
      </template>
    </ol>

    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[0])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(0) ? 'active' : ''">1. {{ tableOfContents[0] }}</h2>
      <p>The personal data we collect in order to offer our services includes your:</p>

      <ul class="bullet-list">
        <li><span>Name;</span></li>
        <li><span>Contact information, including your address, phone number, and email address;</span></li>
        <li><span>Account details, including password;</span></li>
        <li><span>Financial details, including billing information; and</span></li>
        <li><span>Company name.</span></li>
      </ul>

      <p>If you do not provide the required information you may not be able to subscribe to this product.</p>
      <p>In addition to this data, our website uses cookies and other technologies to function effectively. These
        technologies record information about your use of our website, including:</p>

      <ul class="bullet-list">
        <li><span><span class="avenir-heavy">Browser and device data,</span> such as IP address, device type operating system and Internet
                    browser type, screen resolution, operating system name version, device manufacturer and model,
                    language, plug-ins and add-ons;</span>
        </li>
        <li><span><span class="avenir-heavy">Usage data,</span> such as time spent on our website, pages visited, links clicked, language
                    preferences, and the pages that led or referred you to our website.</span>
        </li>
      </ul>

      <p>We also may collect information about your online activities on websites and connected devices over time
        and across third-party websites, devices, apps and other online features and services. We use Google
        Analytics to help us analyze your use of our website, diagnose technical issues and improve our
        website.</p>
      <p>To learn more about the cookies that may be served through our website and how you can control our use of
        cookies and third-party analytics, please see our <a :href="'#' + getContentId(tableOfContents[7])"
                                                             @click="activateSection(7)">Cookie Policy</a>.</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[1])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(1) ? 'active' : ''">2. {{ tableOfContents[1] }}</h2>
      <p>We use the personal data that we collect in order to set up and maintain customer accounts, for
        accounting and invoicing purposes, to manage contracts, orders, and subscriptions, to provide
        troubleshooting and customer support services, for user authentication and security purposes, and to
        create a usage report and log for each user. We process personal data for these purposes because we are
        required to do so in order to perform our contracts with customers.</p>
      <p>We also process personal data for the following business purposes because we have a legitimate interest
        in doing so:</p>

      <ul class="bullet-list">
        <li><span> Maintaining customer account records, in order to administer our business;</span></li>
        <li><span> Maintaining records relating to our customers, in order to administer our business;</span>
        </li>
        <li><span> Managing our customer relationships and communicating with our customers, in order to administer
                    our business and to</span>
        </li>
        <li><span> update our customers about new features and services that we may offer;</span></li>
        <li><span> Complaint handling and dispute resolution, in order to resolve any complaints raised by our
                    customers;</span>
        </li>
        <li><span> Ensuring compliance with applicable MarketSonics policies and procedures relating to our services,
                    in order to ensure the security of our services and monitor compliance with our Terms of Use;</span>
        </li>
        <li><span> Fraud detection and prevention, in order to ensure the security of our services and protect
                    MarketSonics and its users against fraud;</span> and
        </li>
        <!--<li><span>  and</span></li>-->
        <li><span> Carrying out analytics, in order to identify potential improvements to our services and to measure
                    engagement with our advertising campaigns.</span>
        </li>
      </ul>

      <p>In addition to the purposes above, we also process personal data when we are required to do so by law,
        for example in order to maintain accounting records.</p>
      <p>If you consent to you or us doing so, we will share your personal data with any other party forselect
        marketing purposes, nor will we partners, such as Google and LinkedIn, in order to more effectively
        deliver our advertising and to measure the effectiveness of our advertising. We also may use certain
        other non-necessary cookies to collect your personal information, without as described in our policy on
        cookies, with your consent. Our policy on cookies is set forth below.</p>
      <p>If we plan to use the personal data that you provide for other purposes, we will provide an additional
        updated privacy policy to you to describe the purposes for which we will use the personal data and our
        legal basis for doing so.</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[2])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(2) ? 'active' : ''">3. {{ tableOfContents[2] }}</h2>
      <p>We may share personal data with service providers that support the delivery of MarketSonics, including
        payment processors, customer support service providers, online advertising providers, and other service
        providers that process personal data on our behalf. We may also share personal data with certain data
        partners that require it under their terms in order for us to allow access to their data, such as the
        UTP Plan, the Consolidated Tape Association, and other Premium Data Partners. We also share your
        personal data with other entities in the MarketSonics group of companies for the purposes described in
        this Privacy Policy. We have legally binding agreements with all service providers under which they are
        required to use personal data only as necessary to perform services on our behalf and to implement
        appropriate data security and confidentiality obligations, in accordance with applicable law.</p>
      <p>In addition, we may disclose personal data about you (i) if we are required or permitted to do so by law
        or legal process, for example due to a court order or a request from a law enforcement agency, (ii) when
        we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, (iii) in
        connection with an investigation of suspected or actual fraudulent or other illegal activity, and (iv)
        in the event we sell or transfer all or a portion of our business or assets (including in the event of a
        reorganization, dissolution, or liquidation).</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[3])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(3) ? 'active' : ''">4. {{ tableOfContents[3] }}</h2>
      <p>We may transfer the personal data that we collect about you to recipients in countries other than the
        country in which the personal data originally was collected. Those countries may not have the same data
        protection laws as the country in which you initially provided the personal data. When we transfer your
        personal data to recipients in other countries, we will comply with applicable legal requirements
        regarding providing safeguards for the transfer of personal information. These safeguards include
        entering into the European Commission’s Standard Contractual Clauses with counterparties, and/or
        ensuring that counterparties are certified under the EU-U.S. or Swiss-U.S. Privacy Shield Framework.
        Further information about data transfers may be obtained by contacting us as indicated in the “How to
        Contact Us” section of this Privacy Policy.</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[4])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(4) ? 'active' : ''">5. {{ tableOfContents[4] }}</h2>
      <p>MarketSonics maintains technical and organizational safeguards to ensure an appropriate level of security
        and confidentiality for your personal data, in accordance with our policies and applicable
        legislation.</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[5])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(5) ? 'active' : ''">6. {{ tableOfContents[5] }}</h2>
      <p>The time period for which we keep personal data depends on the purpose for which we collected it. In all
        cases we keep it for as long as necessary to fulfil the purposes for which we collected it. We will then
        delete the personal data, unless we are legally required to retain it or if we need to retain it in
        order to comply with our legal obligations (for example, for tax and accounting purposes).</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[6])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(6) ? 'active' : ''">7. {{ tableOfContents[6] }}</h2>
      <p>Subject to applicable law, you may have the right to request access to and rectification of the personal
        data we maintain about you, or to request the restriction of the processing of your personal data or to
        object to that processing on grounds relating to your situation.</p>

      <p>In addition, you may have the right to request erasure of your personal data from MarketSonics’ systems
        in certain circumstances provided by applicable law. Where technically feasible, upon written request
        and verifiable identification, we will provide you with a copy of the personal data provided to us by
        you in a structured, commonly used, machine readable format. To exercise these rights, please contact
        MarketSonics as indicated below. You may lodge a complaint with a data protection authority if you are
        not satisfied with our response.</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[7])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(7) ? 'active' : ''">8. {{ tableOfContents[7] }}</h2>
      <ol>
        <li>
          <span class="list-title"><span class="avenir-heavy"><span class="bullet">(a)</span> Information that we collect Automatically</span></span>
          <div class="list-content">
            <p>When you use our website, we or our third-party service providers may collect certain
              information by automated means, using technologies such as cookies, web server logs, web
              beacons (including tags and pixels), and JavaScript.</p>

            <p>Cookies are files that websites send to your computer or other Internet-connected device to
              uniquely identify your browser or to store information or settings on your device. Your
              browser may tell you how to be notified when you receive certain types of cookies and how to
              restrict or disable certain cookies. Please note, however, that without cookies you may not
              be able to use all of the features of our website.</p>

            <p>In conjunction with gathering information through cookies, our web servers may log
              information such as your operating system type, browser type, domain, and other system
              settings, as well as the language your system uses and the country and time zone where your
              device is located. The web server logs may also record information such as the address of
              the web page that referred you to our website and the IP address of the device you use to
              connect to the Internet. They may also log information about your interaction with our
              website, such as which pages you visit.</p>
          </div>
        </li>
        <li>
          <span class="list-title"><span class="avenir-heavy"><span class="bullet">(b)</span> Categories Of Cookies And Other Automated Technologies</span></span>
          <div class="list-content">
            <ul class="inner-list">
              <li>
                                <span class="list-title"><span
                                    class="avenir-heavy">Strictly Necessary Cookies</span></span>
                <p>These cookies are necessary for our website to operate properly and facilitate
                  your use of its features. Without these cookies, certain services cannot be
                  provided. These cookies help us understand information, such as your session ID
                  and other server authentication information. This category of cookies should not
                  be disabled. If you use your browser settings to block them, we cannot guarantee
                  your security or predict how our website will perform during your visit.</p>
              </li>
              <li>
                <span class="list-title"><span class="avenir-heavy">Analytics Cookies</span></span>
                <p>These cookies collect information about how visitors interact with our website,
                  such as what pages you visit and if any errors have occurred. The information is
                  used to improve how our website functions. These cookies don’t collect
                  information that identifies you but generate statistical data about your use of
                  our website. We use Google Analytics. The service registers a unique ID that is
                  used to generate statistical data on how visitors use our website. If you want
                  to opt out of our use of Google Analytics please click
                  <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.</p>
              </li>
              <li>
                <span class="list-title"><span class="avenir-heavy">Functional Cookies</span></span>
                <p>These cookies allow our website to remember choices you make and provide enhanced
                  and more personal features. They can also be used to remember changes made to
                  parts of our website that you can customise. We also work with the third-party
                  service providers Stripe and Intercom, each of which uses certain cookies. You
                  can find out more about the cookies used by Stripe
                  <a href="https://stripe.com/cookies-policy/legal" target="_blank">here</a> and by Intercom
                  <a href="https://www.intercom.com/legal/terms-and-policies" target="_blank">here</a>.</p>
              </li>
              <li>
                <span class="list-title"><span class="avenir-heavy">Advertising Cookies</span></span>
                <p>These cookies can help us provide more effective advertising on our website. For
                  example, we might use a cookie to help prevent you from seeing the same
                  advertisement multiple times or to measure how many times an advertisement is
                  viewed or clicked on. We use services like Google Tag Manager and LinkedIn
                  cookies for these purposes. You can find further information on those companies’
                  privacy policies <a href="https://policies.google.com/privacy" target="_blank">here</a> and
                  <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">here</a> .</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
                    <span class="list-title"><span class="avenir-heavy"><span
                        class="bullet">(c)</span>  Your Choices</span></span>
          <div class="list-content">
            <p>You can manage cookies through your web browser. Most browsers will tell you how to stop
              accepting new cookies, how to be notified when you receive a new cookie, and how to disable
              existing cookies. You can find out how to do this for your particular browser by clicking
              “help” on your browser’s menu or by visiting
              <a href="https://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a>. Please note,
              however,
              that without cookies you may not be able to take full advantage of all our website
              features.</p>

            <p>The following external links will explain how to manage cookies for the most common
              browsers:</p>

            <ul class="bullet-list dispersed">
              <li><span><span class="avenir-heavy">
                <a href="https://support.google.com/accounts/answer/61416?hl=en"
                   target="_blank">Google Chrome</a></span></span></li>
              <li><span><span class="avenir-heavy">
                <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences"
                   target="_blank">Firefox</a>
              </span></span></li>
              <li><span><span class="avenir-heavy">
                <a href="https://support.apple.com/en-ge/guide/safari/sfri11471/mac" target="_blank">Safari</a>
              </span></span></li>
              <li><span><span class="avenir-heavy">
                <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                   target="_blank">Internet Explorer</a>
              </span></span></li>
              <li><span><span class="avenir-heavy">
               <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  target="_blank">Microsoft Edge</a>
              </span></span></li>
            </ul>
            <p>In addition to the browser-based controls, you can manage third party cookies by visiting <a
                class="link avenir-oblique" target="_blank"
                href="https://optout.aboutads.info/?c=2&lang=EN">www.aboutads.info/choices/</a>
              and <a target="_blank" class="link avenir-oblique" href="https://www.youronlinechoices.eu/">www.youronlinechoices.eu/</a>.
            </p>

          </div>
        </li>
      </ol>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[8])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(8) ? 'active' : ''">9. {{ tableOfContents[8] }}</h2>
      <p>This Privacy Policy may be updated periodically to reflect changes in our personal data practices. We
        will post a notice on our website to notify you of any significant changes to our Privacy Policy and
        indicate at the top of the Privacy Policy when it was most recently updated.</p>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[9])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(9) ? 'active' : ''">10. {{ tableOfContents[9] }}</h2>
      <p>If you have questions about how MarketSonics handles your personal data or would like to exercise your
        rights, please reach out by emailing or writing to us at:</p>
      <contact-info></contact-info>
    </section>
    <div class="divider"></div>
    <section :id="getContentId(tableOfContents[10])">
      <h2 class="avenir-heavy fs-46" :class="isActiveSection(10) ? 'active' : ''">11. {{ tableOfContents[10] }}</h2>
      <p class="date avenir-book fs-26">Last Updated June 09, 2021</p>

      <p>This California Consumer Privacy Statement (“Statement”) supplements the MarketSonics Privacy Policy. It
        applies solely to California consumers and addresses personal information we collect online and offline.
        This Statement does not apply to MarketSonics’ personnel or job applicants.</p>

      <p>This Statement uses certain terms that have the meaning given to them in the California Consumer Privacy
        Act of 2018 and its implementing regulations (the “CCPA”).</p>

      <ol>
        <li>
          <span class="list-title"><span
              class="avenir-heavy">1. Notice of Collection and Use of Personal Information</span></span>
          <div class="list-content">
            <p>We may collect (and may have collected during the 12-month period prior to the effective date
              of this Statement) the following categories of personal information about you:</p>
            <ul class="inner-list">
              <li><p><span class="avenir-heavy">• Identifiers:</span> identifiers such as a real name,
                alias, postal address, unique
                personal identifier (such as a device identifier; cookies, beacons, pixel tags, mobile
                ad identifiers and similar technology; customer number, unique pseudonym, or user alias;
                telephone number and other forms of persistent or probabilistic identifiers), online
                identifier, internet protocol address, email address, account name, and other similar
                identifiers</p></li>
              <li><p><span
                  class="avenir-heavy">• Additional Data Subject to Cal. Civ. Code § 1798.80:</span>
                credit card number,
                debit card number, and other financial information</p></li>
              <li><p><span class="avenir-heavy">• Online Activity:</span> Internet and other electronic
                network activity information,
                including, but not limited to, browsing history, search history, and information
                regarding your interaction with websites, applications or advertisements</p></li>
              <li><p><span class="avenir-heavy">• Geolocation Data:</span> e.g. certain geolocation data
                may be collected to conduct
                security and anti-fraud checks</p></li>
              <li><p><span class="avenir-heavy">• Employment Information:</span> professional or
                employment-related information</p>
              </li>
            </ul>
            <p>We may use the categories of personal information listed above for the purposes described in
              the
              MarketSonics Privacy Policy. In addition, we may use these categories of personal
              information for
              certain business purposes specified in the CCPA, as described in this table:</p>
            <table-component :columns="noticeOfCollection.columns" :rows="noticeOfCollection.rows"></table-component>
          </div>
        </li>
        <li>
          <span class="list-title"><span class="avenir-heavy">2. Our Prior Collection, Use and Disclosure of Personal Information</span></span>
          <div class="list-content">
            <p>We may have collected and used your personal information, as described in Section 1 above,
              during the 12-month period prior to the effective date of this Statement. For the personal
              information collected during that timeframe, we describe below: (a) the categories of
              sources from which we may have obtained the personal information, (b) the categories of
              third parties with whom we may have shared the information, and (c) the categories of
              personal information we may have disclosed for a business purpose.</p>
            <ul class="inner-list">
              <li>
                <span class="list-title"><span class="avenir-heavy"><span class="bullet">a.</span> Sources of Personal Information</span></span>
                <div class="list-content">
                  <p>We may have obtained personal information about you from various sources, as
                    described below.</p>
                  <table-component :columns="priorCollection.columns" :rows="priorCollection.rows"></table-component>
                </div>
              </li>
              <li>
                <span class="list-title"><span class="avenir-heavy"><span class="bullet">b.</span> Sharing of Personal Information</span></span>
                <div class="list-content">
                  <p>We may have shared your personal information with certain categories of third
                    parties, as described below.</p>
                  <table-component :columns="sharing.columns" :rows="sharing.rows"></table-component>
                </div>
              </li>
              <li>
                <span class="list-title"><span class="avenir-heavy"><span class="bullet">c.</span> Sale of Personal Information</span></span>
                <div class="list-content">
                  <p>We do not sell your personal information.</p>
                </div>
              </li>
              <li>
                <span class="list-title"><span class="avenir-heavy"><span class="bullet">d.</span> Disclosure of Personal Information for a Business Purpose</span></span>
                <div class="list-content">
                  <p>We may have disclosed to third parties for a business purpose all of the
                    categories of personal information about you listed in Section 1 above.</p>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li>
                    <span class="list-title"><span
                        class="avenir-heavy">3. California Consumer Privacy Rights</span></span>
          <div class="list-content">
            <p>You have certain choices regarding your personal information, as described below.</p>

            <p><u>Access:</u> You have the right to request, twice in a 12-month period, that we disclose to
              you the personal information we have collected, used, and disclosed about you during the
              past 12 months.</p>

            <p><u>Deletion:</u> You have the right to request that we delete certain personal information we
              have collected from you.</p>

            <p><span class="avenir-heavy">How to Submit a Request:</span> To submit an access or deletion
              request for yourself or as an authorized agent on behalf of a consumer, email us at
              legal@marketsonics.com. For questions or concerns about our privacy policies and practices,
              please contact us as described in the How To Contact Us section of our Privacy Notice.</p>

            <p><span class="avenir-heavy">Verifying Requests:</span> To help protect your privacy and
              maintain security, we will take steps to verify your identity before granting you access to
              your personal information or complying with your request. If you have an account with us, we
              may verify your identity by requiring you to sign in to your account. If you do not have an
              account with us and you request access to or deletion of your personal information, we may
              require you to provide any of the following information: email address; postal address;
              telephone number, or other information. In addition, if you do not have an account and you
              ask us to provide you with specific pieces of personal information, we may require you to
              sign a declaration under penalty of perjury that you are the consumer whose personal
              information is the subject of the request.</p>

            <p><span class="avenir-heavy">Additional Information:</span> If you choose to exercise any of
              your rights under the CCPA, you have the right to not receive discriminatory treatment by
              us. To the extent permitted by applicable law, we may charge a reasonable fee to comply with
              your request.</p>
          </div>
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
import ContactInfo from "../components/ContactInfo";
import TableComponent from "../components/table/TableComponent";

export default {
  name: "PrivacyPolicy",
  components: {TableComponent, ContactInfo},
  data() {
    return {
      tableOfContents: [
        "Personal Data We Collect",
        "How We Use The Personal Data We Collect",
        "How We Share Personal Data",
        "Data Transfers",
        "How We Protect Personal Data",
        "Retention of Personal Data",
        "Your Rights",
        "Cookies",
        "Updates to Our Privacy Policy",
        "How to Contact Us",
        "California Consumer Privacy Statement",
      ],
      activeSectionIndex: -1,
      noticeOfCollection: {
        columns: [{title: "Purposes"}, {title: "Categories of Personal Information"}],
        rows: [
          {
            purposes: "Performing services, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytics services, or providing similar services",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Auditing related to a current interaction with you and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Debugging to identify and repair errors that impair existing intended functionality",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Undertaking internal research for technological development and demonstration",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Undertaking activities to verify or maintain the quality or safety of a service that is owned or controlled by us, and to improve, upgrade, or enhance the service that is owned or controlled by us",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
        ]
      },
      priorCollection: {
        columns: [{title: "Categories of Sources of Data Collection"}, {title: "Categories of Personal Information"}],
        rows: [
          {
            purposes: "Directly from you, such as when you visit our facilities, when you sign-up to receive communications from MarketSonics, when you sign up for MarketSonics services, when you contact us with a comment or question, when you participate in an event, conference or other activity sponsored by MarketSonics, when you participate in a public consultation, opinion, survey, focus group or provide us with feedback regarding our services",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "From monitoring tools, such as cookies that track your use of our websites",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Our affiliates",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Businesses that we are evaluating in connection with a potential investment or business opportunity",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Vendors (e.g., background check providers), advisors, referral partners and other business partners\t",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Recruiting agencies, social media network or publicly available sources",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
        ]
      },
      sharing: {
        columns: [{title: "Categories of Third Parties"}, {title: "Categories of Personal Information"}],
        rows: [
          {
            purposes: "Our affiliates",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Vendors, broker-dealers, advisors and others who provide services to us or on our behalf",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Professional services organizations, such as auditors and law firms",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Data partners, such as the UTP Plan, the Consolidated Tape Association, and other Premium Data Partners",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Government entities, including regulatory agencies and law enforcement",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
          {
            purposes: "Operating systems and platforms",
            categories: "Identifiers; Additional Data Subject to Cal. Civ. Code § 1798.80; Online Activity; Geolocation Data; Employment Information",
            categoriesBold: true
          },
        ]
      }
    }
  },
  methods: {
    getContentId(content) {
      let link = content.toLowerCase().split(" ").join("-")
      return link
    },
    isActiveSection(index) {
      return this.activeSectionIndex === index
    },
    activateSection(index) {
      // let element = document.getElementById(this.getContentId(this.tableOfContents[index]));
      // element.scrollIntoView();
      // window.scrollTo(0, element.offsetTop);
      // window.scroll({
      //   top: 500,
      //   left: 0,
      //   behavior: 'smooth'
      // });
      // element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      this.activeSectionIndex = index
    }
  }
}
</script>

<style scoped lang="scss">
@import "../design/index";

.divider {
  position: relative;
  display: block;
  width: 100%;
  height: 0.5px;
  background-color: $blue;
  margin: calc(10px + 2.8vh) 0;
}

.privacy-policy {
  position: relative;
  width: 1350px;
  /*width: 75%;*/
  color: $color-main-bg;
  margin: calc(50px + 5vw) auto;

  section {
    scroll-margin-top: 150px;
  }

  a {
    text-decoration: inherit;
    color: inherit;
  }

  a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }

  a:hover {
    color: $blue;
  }

  p {
    line-height: 32px;
    padding-bottom: calc(15px + 1.5vh);
  }

  h2 {
    margin: calc(20px + 2.8vw) 0 calc(15px + 1.2vw);

    &.active {
      color: $blue;
    }
  }
}

.header-label {
  text-transform: uppercase;
}

.date {
  color: $blue;
}

.table-of-contents {
  padding-left: calc(15px + 1.8vw);
  border-left: solid 0.5px $blue;
  margin: 15px 0 calc(25px + 3vw);

  li {
    margin: calc(15px + 1vw) 0;
  }

  a {
    text-decoration: none;
    color: $color-main-bg;
    font-weight: bold;

    &:hover {
      color: $blue;
    }
  }

}

.inner-list {
  margin-left: calc(10px + 2vw);
}

.bullet-list {
  list-style: outside;
  margin-left: calc(30px + 1.8vw);
  margin-bottom: calc(15px + 1.7vh);

  &.dispersed li {
    margin-bottom: calc(5px + 0.5vh);
  }

  li {
    span {
      position: relative;
      line-height: 1.4;
    }
  }
}

.list-content {
  margin: calc(10px + 1.5vh) 0;
}

.list-title {
  display: block;
  text-transform: capitalize;
  margin-bottom: calc(10px + 1.5vh) !important;

  .bullet {
    text-transform: lowercase;
  }
}

.link {
  color: $blue;
  text-decoration: underline;
}


@media only screen and (max-width: 1600px) {
  .privacy-policy {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .divider[data-v-d5b2b434] {
    margin: calc(10px + 1.8vh) 0;
    width: 120%;
    left: -10%;
  }

  .privacy-policy p {
    line-height: 28px;
  }

}


</style>
