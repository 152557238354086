<template>
    <div class="main-container">
        <header-nav @makeVisible="contactUsVisible = true"></header-nav>

        <contact-us v-if="contactUsVisible" @close="contactUsVisible = false"></contact-us>
        <privacy-policy v-if="privacyPolicyVisible" @close="privacyPolicyVisible = false"></privacy-policy>
        <terms-and-conditions v-if="termsAndConditionsVisible"
                              @close="termsAndConditionsVisible = false"></terms-and-conditions>

        <div class="middle-header">

        </div>

        <section class="section-container intro">
            <div class="content">
                <div class="middle-content">
                    <h1 class="fs-130">Quant As A Service</h1>
                    <h1 class="fs-130 blue">(QaaS)</h1>
                    <p class="fs-30">Powering the quantitative finance landscape from infrastructure to solutions</p>
                </div>

                <div class="contact-us-container">
                    <p class="fs-30">Contact Us Now</p>
                    <img src="../assets/icons/chat.svg">
                </div>
            </div>
        </section>

        <section class="section-container">
            <div class="content">
                <div class="title-container">
                    <h1 class="fs-130">Power The</h1>
                    <h1 class="fs-130">Quant Landscape</h1>
                    <img src="">
                </div>

                <div class="slider-dots">
                    <div class="dot" :class="[sliderOneVisible ? 'active' : '']" @click="changeText()"></div>
                    <div class="dot" :class="[sliderOneVisible ? '' : 'active']" @click="changeText()"></div>
                </div>

                <div class="slider-texts">
                    <p v-if="sliderOneVisible" class="fs-50">
                        At MarketSonics, we provide a service that lets financial institutions seamlessly integrate
                        their
                        infrastructure, data, analytics and solutions into one streamlined process. Our service empowers
                        entire organizations to develop innovative applications and solve challenging problems quickly
                        and efficiently.
                    </p>
                    <p v-else class="fs-50">
                        At MarketSonics, we provide a service that lets financial institutions seamlessly integrate
                        their
                        infrastructure, data, analytics and solutions into one streamlined process.
                    </p>
                </div>
            </div>
        </section>

        <section class="section-container grid blue">
            <div class="content grid">
                <div class="title-container grid">
                    <h1 class="fs-130">Why We’re Here <img src="../assets/icons/target.svg"></h1>
                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">1.</div>
                    <p class="fs-30">
                        With the significant technological innovations and the exponential growth in data of the last
                        decade, financial institutions need to be more creative to find solutions to challenging
                        problems.
                    </p>
                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">2.</div>
                    <p class="fs-30">
                        In 2015, when we looked at the quantitative finance landscape, we saw infrastructures that were
                        too rigid to handle novel problems, and custom systems that took too long to deploy and were too
                        difficult to maintain and upgrade.
                    </p>
                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">3.</div>
                    <p class="fs-30">
                        We saw quantitative models that failed against the growing velocity and volume of data, and
                        analytics that were unsuitable for the increasingly volatile financial markets, forcing
                        organizations to settle for less than optimal and costly solutions.
                    </p>
                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">4.</div>
                    <p class="fs-30">
                        It was then apparent to us that the industry needed a different way of approaching these
                        problems, but also a different kind of company to address them. That’s why we founded
                        MarketSonics.
                    </p>
                </div>
            </div>
        </section>

        <section class="section-container grid black">
            <div class="content grid">
                <div class="title-container grid">
                    <h1 class="fs-130">What We Do <img src="../assets/icons/gear.svg"></h1>

                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">1.</div>
                    <p class="fs-30">
                        We are a trusted provider of quantitative analytics and solutions as a service, in the fixed
                        income and derivatives markets.
                    </p>
                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">2.</div>
                    <p class="fs-30">
                        We cover asset classes such as Corporate Bonds, Municipal Bonds, Mortgage and Asset Backed
                        Securities, Structured Products as well as Derivatives on Equity, Futures, Commodities, and FX.
                    </p>
                </div>

                <div class="cell-container">
                    <div class="counter-container fs-30">3.</div>
                    <p class="fs-30">
                        We use a quantitatively driven approach on top of a modern cloud technology infrastructure to
                        tackle large scale, time-sensitive, and mission-critical quantitative problems where the number
                        of instruments and positions are in the millions.
                    </p>
                </div>
            </div>
        </section>

        <section class="section-container grid white">
            <div class="content grid">
                <div class="title-container grid">
                    <h1 class="fc-black fs-130">Who We Serve <img src="../assets/icons/smile.svg"></h1>

                </div>

                <div class="cell-container">
                    <div class="fc-black counter-container fs-30">1.</div>
                    <p class="fc-black fs-30">
                        Our clients span a wide spectrum of companies, serving the financial services industry, that
                        have a need for bespoke on-demand Quant services.
                    </p>
                </div>

                <div class="cell-container">
                    <div class="fc-black counter-container fs-30">2.</div>
                    <p class="fc-black fs-30">
                        We seek out to solve our clients most challenging problems where the stakes are real. Whether
                        it’s a financial software or data vendor who needs to add analytics products to its existing
                        offering, or a proprietary trading desk seeking to build a backend computational engine for algo
                        trading, or an asset manager looking to enhance its portfolio’s risk analytics.
                    </p>
                </div>

            </div>
        </section>

        <section class="section-container blue">
            <div class="content">
                <div class="title-container principles">
                    <h1 class="fc-white fs-130">QaaS Principles</h1>
                </div>

                <div class="slider-texts principles">
                    <p class="fc-white fs-50">
                        MarketSonics’ QaaS is a service that reimagines how people approach quantitative modeling by
                        removing the barriers among the various components of the development life cycle. QaaS allows
                        financial institutions to deploy infrastructure, source data, build analytics, and create
                        innovative solutions. MarketSonics' QaaS is built on four core pillars
                    </p>
                </div>
            </div>
        </section>

        <section class="section-container about infrastructure white">
            <div class="content">
                <div class="title-wrapper">
                    <h1 class="fc-black fs-130">Infrastructure</h1>
                </div>

                <div class="count-wrapper">
                    <div class="count fs-130">.01</div>
                </div>

                <div class="p-wrapper">
                    <p class="fs-50">
                        We leverage modern cloud technologies and high-performance computing to engineer scalable,
                        agile,
                        and
                        secured infrastructures; which we use deliver quantitative analytics and solutions in
                        large-scale,
                        time-sensitive and mission-critical applications.
                    </p>
                </div>

                <div class="subgrid">
                    <div class="cell-container cell-11 secured">
                        <img src="../assets/icons/secured.svg"/>
                        <p class="fc-black fs-46">Secured</p>
                    </div>

                    <div class="cell-container cell-12"></div>

                    <div class="cell-container cell-21 agile">
                        <img src="../assets/icons/agile.svg"/>
                        <p class="fc-black fs-46">Agile</p>
                    </div>

                    <div class="cell-container cell-22 scalable">
                        <img src="../assets/icons/scalable.svg"/>
                        <p class="fc-black fs-46">Scalable</p>
                    </div>
                </div>

            </div>
        </section>

        <section class="section-container about data white">
            <div class="content">

                <div class="title-wrapper">
                    <h1 class="fc-black fs-130">Data</h1>
                </div>

                <div class="count-wrapper">
                    <div class="count fs-130">.02</div>
                </div>

                <div class="p-wrapper">
                    <p class="fs-50">
                        We have extensive experience working with major data vendors, in the fixed income and
                        derivatives
                        markets, to aggregate diverse datasets from multiple sources into big and reliable data
                        repositories
                        that are many times more valuable than the independent datasets and directly amenable to
                        quantitative modeling.
                    </p>
                </div>

                <div class="subgrid">
                    <div class="cell-container cell-11"></div>

                    <div class="cell-container cell-12 big">
                        <img src="../assets/icons/big.svg"/>
                        <p class="fc-black fs-46">Big</p>
                    </div>

                    <div class="cell-container cell-21 reliable">
                        <img src="../assets/icons/reliable.svg"/>
                        <p class="fc-black fs-46">Reliable</p>
                    </div>

                    <div class="cell-container cell-22 valuable">
                        <img src="../assets/icons/valuable.svg"/>
                        <p class="fc-black fs-46">Valuable</p>
                    </div>
                </div>

            </div>
        </section>

        <section class="section-container about analytics white">
            <div class="content">
                <div class="title-wrapper">
                    <h1 class="fc-black fs-130">Analytics</h1>
                </div>

                <div class="count-wrapper">
                    <div class="count fs-130">.03</div>
                </div>

                <div class="p-wrapper">
                    <p class="fs-50">
                        Using state of art algorithms and bespoke quantitative models as well as best in class software
                        engineering concepts, we have developed a suite of advanced and versatile analytics that empower
                        our
                        clients to create innovative applications.
                    </p>
                </div>

                <div class="subgrid">
                    <div class="cell-container cell-11 advanced">
                        <img src="../assets/icons/advanced.svg"/>
                        <p class="fc-black fs-46">Advanced</p>
                    </div>

                    <div class="cell-container cell-12 versatile">
                        <img src="../assets/icons/versatile.svg"/>
                        <p class="fc-black fs-46">Versatile</p>
                    </div>

                    <div class="cell-container cell-21"></div>

                    <div class="cell-container cell-22 empowering">
                        <img src="../assets/icons/empowering.svg"/>
                        <p class="fc-black fs-46">Empowering</p>
                    </div>
                </div>

            </div>
        </section>

        <section class="section-container about solutions white">
            <div class="content">
                <div class="title-wrapper">
                    <h1 class="fc-black fs-130">Solutions</h1>
                </div>

                <div class="count-wrapper">
                    <div class="count fs-130">.04</div>
                </div>

                <div class="p-wrapper">
                    <p class="fs-50">
                        We work in concert with our clients to create practical, compelling, and unique solutions; using
                        a
                        quantitatively driven approach and a rapid prototyping framework, all powered by our advanced
                        analytics.
                    </p>
                </div>

                <div class="subgrid">
                    <div class="cell-container cell-11 practical">
                        <img src="../assets/icons/practical.svg"/>
                        <p class="fc-black fs-46">Practical</p>
                    </div>

                    <div class="cell-container cell-12 unique">
                        <img src="../assets/icons/group.svg"/>
                        <p class="fc-black fs-46">Unique</p>
                    </div>

                    <div class="cell-container cell-21 compelling">
                        <img src="../assets/icons/compelling.svg"/>
                        <p class="fc-black fs-46">Compelling</p>
                    </div>

                    <div class="cell-container cell-22"></div>

                </div>

            </div>
        </section>

        <section class="section-container footer black">
            <div class="content">
                <div class="left">
                    <img src="../assets/icons/marketsonics.svg">

                    <div class="bottom-wrapper">
                        <ul class="footer-nav">
                            <li class="fs-30" @click="contactUsVisible = true">Contact Us</li>
                            <li class="fs-30" @click="privacyPolicyVisible = true">Privacy Policy</li>
                            <li class="fs-30" @click="termsAndConditionsVisible = true">Terms Of Use</li>
                        </ul>

                        <p class="fs-30">Copyright @ 2021 MarketSonics LLC. All rights reserved</p>
                    </div>

                </div>
                <div class="right">

                    <div class="social-wrapper">
                        <span class="fs-30">LinkedIn</span>
                        <img class="visible" src="../assets/icons/linkedin.svg">
                        <img src="../assets/icons/linkedin-black.svg">
                    </div>

                    <div class="social-wrapper">
                        <span class="fs-30">Twitter</span>
                        <img class="visible" src="../assets/icons/twitter.svg">
                        <img src="../assets/icons/twitter-black.svg">
                    </div>

                    <div class="social-wrapper">
                        <span class="fs-30">YouTube</span>
                        <img class="visible" src="../assets/icons/youtube.svg">
                        <img src="../assets/icons/youtube-black.svg">
                    </div>

                    <div class="social-wrapper">
                        <span class="fs-30">GitHub</span>
                        <img class="visible" src="../assets/icons/github.svg">
                        <img src="../assets/icons/github-black.svg">
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import HeaderNav from "../components/navigation/HeaderNav";
    import ContactUs from "../components/ContactUs";
    import PrivacyPolicy from "../components/OldPrivacyPolicy";
    import TermsAndConditions from "../components/TermsAndConditions";

    export default {
        name: "OldMain",
        components: {TermsAndConditions, PrivacyPolicy, ContactUs, HeaderNav},

        data() {
            return {
                sliderOneVisible: true,
                contactUsVisible: false,
                privacyPolicyVisible: false,
                termsAndConditionsVisible: false,
            }
        },

        methods: {
            changeText() {
                this.sliderOneVisible = !this.sliderOneVisible;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../design/index';

    .main-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .section-container {
        display: table;
        width: 100%;
        max-width: none;
        height: 100vh;
        margin: 0;
        background-color: #373B44;

        &:nth-of-type(2n) {
            background-color: $white;
        }

        &.blue {
            background-color: $blue;
        }

        &.black {
            background-color: $black;
        }

        &.white {
            background-color: $white;
        }
    }

    .slider-dots {
        display: flex;
        align-items: flex-end;
        height: 20%;

        .dot {
            width: 17px;
            height: 17px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            background-color: #d2d9dc;
            margin-right: 30px;
            cursor: pointer;

            &.active {
                background-color: $blue;
            }
        }
    }

    .intro {
        height: 100vh;

        .content {
            background-color: #4e4c4c;
            background-image: url('../assets/images/bg1.png');
            -webkit-background-size: cover;
            background-size: cover;
            background-blend-mode: multiply;
            position: relative;
        }
    }

    .title-container {
        text-align: left;
        height: 40%;

        &.principles {
            height: 20%;
        }

        h1 {
            /*font-size: 150px;*/
            color: $black;
            line-height: 1.1;
            font-family: $avenir-black;

            &.fc-white {
                color: $white
            }
        }

        &.grid {
            h1 {
                color: $white;
                position: relative;

                &.fc-black {
                    color: $black;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    margin-left: 30px;
                    max-width: 70px;
                }
            }
        }
    }

    .slider-texts {
        display: flex;
        align-items: flex-end;
        height: 40%;
        max-width: 80%;

        &.principles {
            height: 80%;
        }

        p {
            /*font-size: 50px;*/
            font-family: $avenir-light;
            color: $black;
            text-align: left;
            line-height: 1.12;
            width: 100%;

            &.fc-white {
                color: $white
            }
        }
    }

    .content {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        padding: 5.5vh 5vw 6vh 3.4vw;
        width: 100%;
        box-sizing: border-box;

        .middle-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 70%;
            margin-bottom: 10%;

            h1 {
                /*font-size: 130px;*/
                /*font-size: 12.05vh;*/
                font-family: $avenir-black;
                line-height: 1.2;

                &.blue {
                    color: $blue;
                }
            }

            p {
                line-height: 1.6;
                font-family: $avenir-book;
                /*font-size: 30px;*/

            }
        }

        .contact-us-container {
            position: absolute;
            right: 60px;
            bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                /*color: #fff;*/
                font-family: $avenir-book;
                /*font-size: 30px;*/
                color: $blue;
                writing-mode: vertical-rl;
                /*line-height: 55px;*/
                /*margin-left: 20px;*/
                margin-bottom: 25px;
                transform: rotate(180deg);
                /*padding-right: 12px;*/
                /*padding-left: 0;*/
            }

        }

        &.grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            align-items: center;

            .title-container {
                grid-row-start: 1;
                grid-row-end: 4;
                height: auto;
                align-self: flex-start;
                max-width: 90%;
            }
        }

    }

    .section-container.grid {
        &.blue {
            .cell-container {
                &:nth-child(2) {
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    padding-left: 0;
                }

                &:nth-child(3) {
                    padding-right: 0;
                }

                &:nth-child(4) {
                    padding-left: 0;
                    padding-top: 50px;
                }

                &:nth-child(5) {
                    padding-top: 50px;
                    padding-right: 0;
                }

                &:last-child {
                    border-top: 1px solid rgba(255, 255, 255, 0.5);
                    border-left: 1px solid rgba(255, 255, 255, 0.5);
                    margin-top: -2px;
                    margin-left: -1px;
                }
            }
        }

        &.black {
            .cell-container {

                &:nth-child(2) {
                    padding-left: 0;
                }

                &:nth-child(3) {
                    border-left: 1px solid rgba(255, 255, 255, 0.5);
                    margin-left: -1px;
                    padding-right: 0;
                }

                &:nth-child(4) {
                    padding-left: 0;
                    padding-top: 50px;
                }

                &:nth-child(5) {
                    padding-top: 50px;
                    padding-right: 0;
                }

                &:last-child {
                    border-top: 1px solid rgba(255, 255, 255, 0.5);
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                }
            }
        }
    }

    .cell-container {
        color: $white;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 33px 38px;
        box-sizing: border-box;


        p {
            color: $white;
            text-align: left;
            /*max-width: 90%;*/

            &.fc-black {
                color: $black;
            }
        }

        .counter-container {
            width: 6.5vw;
            height: 6.5vh;
            border: 2px solid;
            border-radius: 50px;
            line-height: 6.5vh;
            /*font-size: 30px;*/
            font-family: $avenir-book;
            margin-bottom: 4.5vh;

            &.fc-black {
                color: $black;
            }
        }
    }

    .section-container.white {

        .title-container {
            max-width: 80%;
        }

        .cell-container {
            grid-column-start: 2;
            grid-column-end: 4;
            padding: 40px 0;

            &:last-child {
                border-top: solid 0.5px #131415;
            }
        }
    }

    .about {
        &.solutions {
            .subgrid {
                border-bottom: none;
            }

            .p-wrapper {
                border-bottom: none;
            }
        }

        .content {
            display: grid;
            /*grid-gap: 1px;*/
            padding: 0 3.4vw 0 3.4vw;
            grid-template-columns: 3fr 2fr;
            grid-template-rows: 6fr 19fr;
            align-items: center;

        }

        .subgrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            height: 100%;
            border-top: 1px solid rgba(75, 122, 255, .5);
            border-bottom: 1px solid rgba(75, 122, 255, .5);
        }

        .title-wrapper {
            width: 100%;
            height: 100%;
            padding-top: 90px;
            box-sizing: border-box;

            h1 {
                color: $black;
                /*font-size: 130px;*/
                font-family: $avenir-black;
                line-height: 1.12;
                text-align: left;
                align-self: center;
            }
        }

        .count-wrapper {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-left: 1px solid rgba(75, 122, 255, .5);

            .count {
                color: $blue;
                /*font-size: 130px;*/
                font-family: $avenir-black;
                line-height: 1.12;
                grid-column: 2;
                text-align: right;
                align-self: center;
                border-left: 1px solid rgba(75, 122, 255, .5);
                height: 100%;
                padding-top: 90px;
                box-sizing: border-box;
                margin-left: -2px;
            }
        }

        .p-wrapper {
            width: 100%;
            height: 100%;
            border-right: 1px solid rgba(75, 122, 255, .5);
            border-top: 1px solid rgba(75, 122, 255, .5);
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid rgba(75, 122, 255, .5);

            p {
                color: $black;
                /*font-size: 50px;*/
                font-family: $avenir-light;
                line-height: 1.12;
                text-align: left;
                max-width: 87%;
                padding-bottom: 6vh;
            }
        }

        .cell-container {
            align-items: center;
            justify-content: center;
            border: none;

            &.cell-11 {
                grid-column: 1;
                border-right: 1px solid rgba(75, 122, 255, .5);
            }

            &.cell-12 {
                grid-column: 2;
            }

            &.cell-21 {
                grid-column: 1;
                border-right: 1px solid rgba(75, 122, 255, .5);
                border-top: 1px solid rgba(75, 122, 255, .5);
            }

            &.cell-22 {
                grid-column: 2;
                border-top: 1px solid rgba(75, 122, 255, .5) !important;
            }

            p {
                /*font-size: 46px;*/
                font-family: $apercu-mono;
            }

            img {
                max-height: 150px;
            }
        }
    }

    .footer {
        .left {
            width: 47%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 6.5vh 60px 3vh 3.4vw;

            .footer-nav {
                text-align: left;
                margin-bottom: 5.6vh;

                li {
                    color: $white;
                    /*font-size: 30px;*/
                    font-family: $apercu-mono;
                    margin-bottom: 15px;
                    line-height: 1.35;
                }
            }

            img {
                max-height: 35px;
            }

            p {
                color: $grey;
                /*font-size: 30px;*/
                font-family: $avenir-light;
                text-align: left;
                opacity: 0.5;
            }
        }

        .right {
            width: 53%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            border-left: 1px solid rgba(75, 122, 255, .5);

            .social-wrapper {
                padding: 6vh 3.4vw;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &:first-child {
                    border-right: 1px solid rgba(75, 122, 255, .5);
                    border-bottom: 1px solid rgba(75, 122, 255, .5);
                }

                &:last-child {
                    border-left: 1px solid rgba(75, 122, 255, .5);
                    border-top: 1px solid rgba(75, 122, 255, .5);
                    margin-left: -1px;
                    margin-top: -1px;
                }

                img {
                    max-height: 65px;
                    display: none;
                    align-self: flex-end;

                    &.visible {
                        display: inline-block;
                    }
                }

                span {
                    color: $white;
                    /*font-size: 30px;*/
                    font-family: $apercu-mono;
                    align-self: flex-start;
                }
            }
        }

        .content {
            display: flex;
            justify-content: space-between;
            padding: 0;
        }
    }

    h1 {
        display: block;
        /*font-size: 3em;*/
        font-family: $avenir-black;
        font-weight: 300;
        color: white;
    }

    p {
        /*font-size: 1.5em;*/
        font-family: $avenir-light;
        font-weight: 500;
        color: #C3CAD9;
    }

    a {
        position: relative;
        font-weight: 700;
        color: #373B44;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            top: 1px;
        }
    }

    @media only screen and (max-width: 1700px) {

        .slider-texts {
            height: 35%;
        }

        .title-container {
            height: 50%;
        }

        .slider-dots {
            height: 15%;
        }

        .about .title-wrapper {
            padding-top: 80px;
        }
    }

    @media only screen and (max-width: 1600px) {
        .cell-container {

            .counter-container {
                margin-bottom: 3.7vh;
            }
        }

        .content {
            padding: 6.5vh 5vw 6vh 3.4vw;
        }

        .about {
            .cell-container {
                img {
                    max-height: 120px;
                }
            }
        }

        .footer {
            .right {
                .social-wrapper {
                    img {
                        max-height: 50px;
                    }
                }
            }
        }
        .footer .left p {
            font-size: calc(10px + 1.3vh);
        }

        .title-container.grid h1 img {
            max-width: 50px;
            margin-left: 15px;
        }

        .cell-container {
            padding: 20px 25px 10px;
        }
    }

    @media only screen and (max-width: 1500px) {
        .content {
            padding: 4.5vh 5vw 4vh 3.4vw;
        }

        .cell-container .counter-container {
            width: 110px;
            height: 5.5vh;
            line-height: 5.5vh;
            margin-bottom: 1.7vh;
        }
    }

    @media only screen and (max-width: 1400px) {

        .cell-container .counter-container {
            width: 90px;
        }

        .title-container.grid h1 img {
            max-width: 40px;
        }

        .footer .right .social-wrapper img {
            max-height: 35px;
        }

        .footer .left img {
            max-height: 30px;
        }
    }

    @media only screen and (max-width: 1200px) {

        .about .cell-container img {
            max-height: 70px;
            margin-bottom: 10px;
        }

        .title-container.grid h1 img {
            margin-left: 7px;
        }
    }

    @media only screen and (max-width: 1050px) {

        .content {
            display: block;
        }

        .section-container {
            display: inline-block;
            height: auto;
        }

        .about .content {
            display: flex;
            flex-direction: column;
        }

        .about .title-wrapper {
            padding-bottom: 15%;
            padding-top: 50px;
            order: 2;
        }

        .about .count-wrapper .count {
            padding-top: 72px;
        }

        .about .count-wrapper {
            height: auto;
            display: grid;
            order: 1;
            grid-template-columns: 2fr 1fr;
            border-bottom: 1px solid rgba(75, 122, 255, 0.5);
            border-left: none;
        }

        .about .p-wrapper {
            height: auto;
            order: 3;
            border: none;
        }

        .about .subgrid {
            height: auto;
            order: 4;
            display: flex;
        }

        .about.infrastructure .cell-container.cell-12 {
            display: none;
        }

        .about.data .cell-container.cell-11 {
            display: none;
        }

        .about.analytics .cell-container.cell-21 {
            display: none;
        }

        .about.solutions .cell-container.cell-22 {
            display: none;
        }

        .section-container.about .cell-container {
            border: none !important;
            padding: 20px 0 5px;
        }

        .about .cell-container p {
            width: 100%;
            text-align: center;
            border-top: 1px solid rgba(75, 122, 255, 0.5);
            padding-top: 5px;
        }

        .about .cell-container img {
            margin-bottom: 20px;
            max-height: 90px;
        }


        .content.grid {
            display: flex;
            flex-direction: column;
            padding: 4.5vh 5vw 6vh 3.4vw;
        }


        .section-container.grid {

            .content.grid {
                .cell-container {
                    height: auto;
                    padding: 40px 0 50px;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                }
            }

            &.black {
                .cell-container {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
                }
            }

            &.blue {
                .cell-container {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
                }
            }

            &.white {
                .cell-container {
                    border-bottom: 1px solid rgba(19, 20, 21, 0.5) !important;
                }
            }
        }


        .content .middle-content {
            margin-bottom: 20%;
            margin-top: 20%;
            align-items: flex-start;
        }

        .content .contact-us-container p {
            display: none;
        }

        .content .contact-us-container img {
            width: 70px;
        }

        .content .middle-content h1 {
            text-align: left;
        }

        .content .middle-content p {
            text-align: left;
            margin-top: 30px;
        }

        .slider-dots {
            padding: 50px 0;
        }

        .title-container.grid h1 img {
            max-width: 60px;
        }

        .content.grid .title-container {
            margin-bottom: 10%;
        }

        .cell-container .counter-container {
            margin-bottom: 3.7vh;
        }

        .section-container.grid .cell-container:last-child {
            border-bottom: none !important;
        }

        .title-container.principles {
            padding-bottom: 20%;
        }

        .slider-texts p {
            line-height: 1.32;
        }

        .footer .content {
            flex-direction: column;
            position: relative;

            .left {
                width: 100%;
                box-sizing: border-box;

                .bottom-wrapper {
                    /*margin-top: 60vh;*/
                    margin-top: calc(100px + 60vh);
                }
            }

            .right {
                width: 100%;
                border-top: 1px solid rgba(75, 122, 255, 0.5);
                border-bottom: 1px solid rgba(75, 122, 255, 0.5);
                position: absolute;
                top: calc(100px + 6.5vh);
                border-left: none;

                .social-wrapper {
                    padding: 20px;
                    height: 20vh;
                }
            }
        }

        .about .count-wrapper, .about .count-wrapper .count {
            height: 100%;
            border-left: none;
        }
    }

    @media only screen and (max-width: 900px) {

        .title-container.grid h1 img {
            max-width: 45px;
            margin-left: 15px;
        }

        /*.contact-us-modal .contact-us button {*/
            /*width: 24vw;*/
        /*}*/

        /*.contact-us-modal .contact-us button {*/
            /*padding: 0 10vw;*/
            /*height: 7.3vh;*/
            /*width: auto;*/
        /*}*/

        .contact-us-modal .contact-us .contact-inputs .input-container {
            width: 100%;
            height: auto;
            padding-top: 2vh;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .contact-us-modal .contact-us .contact-inputs .input-container input {
            padding-top: 1vh;
        }

        .contact-us-modal .contact-us {
            text-align: left;
        }

        .contact-us-modal {
            overflow-y: scroll;
        }

        .contact-us-modal .contact-us p {
            text-align: left;
        }

        .contact-us-modal .contact-us .contact-inputs p {
            text-align: center;
        }
    }

    @media only screen and (max-width: 650px) {

        .about .cell-container img {
            max-height: 65px;
        }

        .content .contact-us-container {
            right: 30px;
            bottom: 30px;
        }

        .content .contact-us-container img {
            width: 50px;
        }

        .title-container.grid h1 img {
            max-width: 38px;
            margin-left: 10px;
        }

        .slider-dots .dot {
            margin-right: 25px;
        }

        .footer .right .social-wrapper span {
            display: none;
        }

        .footer .right .social-wrapper {
            justify-content: flex-end;
        }

        .contact-us-modal .contact-us {
            width: 90%;
        }


    }

    @media only screen and (max-width: 450px) {

        .slider-texts {
            max-width: 100%;
        }

        .slider-dots .dot {
            width: 14px;
            height: 14px;
            margin-right: 20px;
        }

        .title-container.grid h1 img {
            max-width: 30px;
            margin-left: 10px;
        }

        .content.grid .title-container {
            margin-bottom: 5%;
        }

        .section-container.grid .content.grid .cell-container {
            padding: 30px 0 40px;
        }

        .cell-container .counter-container {
            margin-bottom: 3vh;
            width: 80px;
            height: 4.5vh;
            line-height: 4.5vh;
        }

        .about .cell-container img {
            margin-bottom: 20px;
            max-height: 50px;
        }

        .about .title-wrapper {
            padding-bottom: 8%;
            padding-top: 30px;
        }

        .footer .right .social-wrapper img {
            max-height: 30px;
        }

        .footer .left p {
            font-size: calc(10px + 1vh);
        }

        .container {
            padding: 4.5vh 5vw 4vh 3.4vw;
        }

        .container .logo-container img {
            max-height: 20px;
        }

        .content .contact-us-container {
            right: 30px;
            bottom: 30px;
        }

        .content .contact-us-container img {
            width: 50px;
        }

        .about .count-wrapper .count {
            padding-top: 52px;
        }

        .about .p-wrapper p {
            padding-bottom: 4vh;
            max-width: 92%;
        }

        .content {
            padding: 4.5vh 4.4vw 4vh 4.4vw;
        }

        .about .content {
            padding: 0 4.4vw 0 4.4vw;
        }

        .content.grid {
            padding: 4.5vh 5vw 4vh 3.4vw;
        }

        .privacy-policy-modal .policy-wrapper {
            margin: 0 6vw;
        }

        .privacy-policy-modal .policy-wrapper .text-wrapper {
            max-width: 95%;
        }

        .privacy-policy-modal .close-btn-container {
            width: 48px;
            height: 48px;
        }

        .contact-us-modal .close-btn-container {
            width: 48px;
            height: 48px;
        }

        .contact-us-modal .contact-us h1 {
            display: inline-block;
            width: 60%;
        }

        .privacy-policy-modal .policy-wrapper h1 {
            max-width: 60%;
        }

        .contact-us-modal .contact-us button {
            width: 50vw;
            height: 8vh;
        }

        .contact-us-modal .contact-us .contact-inputs .input-container {
            padding-top: 3vh;
            padding-bottom: 0;
        }

        .contact-us-modal .contact-us .contact-inputs .input-container input {
            width: 100%;
            height: 5.5vh;
        }

        .contact-us-modal .contact-us {
            margin: 0 auto 20vh;
        }

    }

    @media only screen and (max-width: 380px) {

        .cell-container .counter-container {
            width: 70px;
            height: 5vh;
            line-height: 5vh;
        }

        .footer .left img {
            max-height: 25px;
        }

        .contact-us-modal .contact-us {
            margin: 0 auto 30vh;
        }
    }


</style>
