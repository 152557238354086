<template>
    <div class="table-container">

        <div class="header">
            <ul>
                <li v-for="(column, index) in columns" v-bind:key="index" class="avenir-heavy fs-26">
                    {{column.title}}
                    <!--<strong>{{column.title}}</strong>-->
                </li>
            </ul>
        </div>
        <div class="body">
            <ul>
                <li v-for="(row, index) in rows" v-bind:key="index">
                    <p class="fs-26 width-55">{{row.purposes}}</p>
                    <p class="fs-26 width-35" :class="{'avenir-heavy': row.categoriesBold}">{{row.categories}}</p>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    export default {
        name: "TableComponent",

        props: ['rows', 'columns'],

        data() {
            return {}
        },

    }
</script>

<style lang="scss" scoped>
    @import '../../design/index';


    .header {
        height: 80px;
        width: 100%;
        background-color: $blue;
        color: white;
        padding: 0 33px;
        box-sizing: border-box;
    }

    .header ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        li {
            /*width: 40%;*/

            &:first-child {
                width: 55%;
            }

            &:last-child {
                width: 35%;
            }
        }
    }

    .table-container {
        border: solid 0.5px #4b7aff;
        border-radius: 10px;
        overflow: hidden;
        margin: 46px 0;
        position: relative;
        
        .body {

            li {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding: 40px 30px;
                border-bottom: solid 0.5px #4b7aff;

                &:last-child {
                    border-bottom: none;
                }

                p {
                    /*width: 40%;*/
                    line-height: 1.2;

                    &.width-55 {
                        width: 55%;
                    }

                    &.width-35 {
                        width: 35%;
                    }
                }
            }
        }

    }


    @media only screen and (max-width: 900px) {
        .table-container {
            border-radius: initial;
            width: 114%;
            left: -7%;
        }

        .inner-list .table-container {
            width: 120%;
            left: -12%;
        }
    }

    @media only screen and (max-width: 420px) {
        .inner-list .table-container {
            width: 122%;
            left: -14%;
        }
    }


</style>
